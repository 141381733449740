import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import './App.css';
import Loading from './sources/components/Loading';
import NotFound from './sources/components/404/NotFound';
const UserApp = lazy(()=>import("./sources/components/UserApp"));

function App() {
  return (
    <Suspense fallback={<Loading />}>
      
    <div className="text-center fs-1 fw-bold container-fluid row px-0 mx-0">
      <BrowserRouter>
      <Routes>
        <Route path='/*' element = {<UserApp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      </BrowserRouter>
    </div>
    </Suspense>
  );
}

export default App;
