import React, { lazy, Suspense, useEffect } from 'react';
import Loading from '../Loading';
import {Helmet} from "react-helmet";

const Header = lazy(()=>import("../Header"));
const Footer = lazy(()=>import("../Footer"));
const NotFoundContent = lazy(()=>import("./NotFoundContent"));

export default function NotFound() {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <><Helmet>
    <title>Meta For Data - Page Not Found | 404 Error</title>
    <meta
      name="description"
      content="Oops! The page you're looking for is not available. Please check the URL or visit our homepage to explore our services and resources."
    />
    <meta name="keywords" content="404 error, page not found, Meta For Data, broken link, page missing, website error, Meta For Data homepage" />
  </Helmet>
    <Suspense fallback={<Loading />}>
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 row px-0 display-flex py-0'>
        <Header title="404" subTitle="Page Not Found" />
        <NotFoundContent />
        <Footer />
    </div>
    </Suspense>
    </>
  )
}
